<template>
  <div>
    <div
      id="modalAccessWithInput"
      class="modal fade modal-danger"
      tabindex="-1"
      aria-labelledby="modalAccessWithInput"
      aria-hidden="true"
      :class="showModal ? 'show' : ''"
      :style="{ display: showModal ? 'block' : 'none' }"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              id="modalAccessWithInput"
              class="modal-title"
            >
              Access
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeModal()"
            />
          </div>
          <div class="modal-body">
            <h4>
              <template v-if="!customMessage">
                Authentication required
              </template>
              <template v-else>
                {{ customMessage }}</template>
            </h4>
            <div class="mb-1">
              <label
                class="form-label"
                for="inputDelete"
              >Type your <strong>PASSWORD</strong> and press confirm</label>
              <input
                v-model="password"
                type="password"
                class="form-control"
                name="inputDelete"
              >
            </div>
          </div>
          <div class="modal-footer d-flex align-items-center justify-content-between">
            <button
              type="button"
              class="btn btn-success"
              data-bs-dismiss="modal"
              @click="acceptModal()"
            >
              Confirm
            </button>
            <button
              type="button"
              class="btn btn-flat-danger"
              data-bs-dismiss="modal"
              @click="closeModal()"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showModal"
      class="offcanvas-backdrop fade"
      :class="showModal ? 'show' : ''"
      @click="closeModal()"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    file: { type: Object, required: false, default: () => {} },
  },
  data() {
    return {
      password: '',
      showModal: false,
      url: '',
      name: '',
      requiredFetch: '',
      requiredType: '',
      customMessage: '',
    }
  },
  computed: {
    ...mapGetters({
      show: 'modals/accessModal',
      loggedUser: 'auth/admin',
    }),
  },
  watch: {
    show() {
      this.password = ''
      this.toggleModal()
    },
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal
    },
    closeModal() {
      this.$store.dispatch('modals/showAccessModal', false)
    },
    async acceptModal() {
      const status = await this.$store.dispatch('modals/checkPassword', { userId: this.loggedUser.id, password: this.password })
      this.password = ''
      if (status === 'OK') {
        Vue.swal('Password is correct', '', 'success').then(async () => {
          this.closeModal()
          await this.$store.dispatch('payrolls/downloadFile', { file: this.file })
        })
      } else {
        Vue.swal('Password incorrect', '', 'error')
      }
    },
  },
}
</script>
